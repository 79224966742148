<template>
  <div>
    <div class="big-title">经营信息</div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px" class="demo-ruleForm" label-suffix=":">
      <el-form-item label="公司类型" prop="company_type">
        <el-select v-model="ruleForm.company_type" placeholder="请选择公司类型" style="width: 380px">
          <el-option v-for="(item, index) in companyList" :label="item.label" :value="item.value" :key="`companyList_${item.value}`"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="公司网站" prop="company_web">
        <el-input v-model.trim="ruleForm.company_web" placeholder="请输入公司网站" clearable style="width: 380px"></el-input>
        <div class="message">形如：http://www.baidu.com/</div>
      </el-form-item>
      <el-form-item label="年销售额（万元）" prop="annual_sales">
        <el-input-number v-model="ruleForm.annual_sales" class="number-left" :precision="0" :min="1"  :max="999999999" label="仅输入不得超过9位数的正整数" clearable style="width: 380px" :controls="false"></el-input-number>
        <div class="message">仅输入不得超过9位数的正整数</div>
      </el-form-item>
      <el-form-item label="商品数量" prop="goods_num">
        <el-select v-model="ruleForm.goods_num" placeholder="请选择" style="width: 380px">
          <el-option v-for="(item, index) in goodNumList" :label="item.label" :value="item.value" :key="`goodNumList_${item.value}`"></el-option>
        </el-select>
        <div class="message">某一商品的多种颜色、尺码或包装形式不同时，按一件商品计算。</div>
      </el-form-item>
      <el-form-item label="平均客单价" prop="avg_price">
        <el-select v-model="ruleForm.avg_price" placeholder="请选择" style="width: 380px">
          <el-option v-for="(item, index) in goodNumList" :label="item.label" :value="item.value" :key="`goodNumList_1_${item.value}`"></el-option>
        </el-select>
        <div class="message">用户购物时，单词购买的平均金额。</div>
      </el-form-item>
      <el-form-item label="仓库情况" prop="warehouse_situation">
        <el-select v-model="ruleForm.warehouse_situation" placeholder="请选择" style="width: 380px">
          <el-option v-for="(item, index) in warehouseList" :label="item.label" :value="item.value" :key="`warehouseList_${item.value}`"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="仓库地址" prop="warehouse_address">
        <el-input v-model.trim="ruleForm.warehouse_address" placeholder="请输入仓库地址" clearable style="width: 380px"></el-input>
      </el-form-item>
      <el-form-item label="常用物流" prop="common_logistics">
        <el-input v-model.trim="ruleForm.common_logistics" placeholder="可填写多个，用逗号分隔，如“申通，圆通”，最大50个字" clearable style="width: 380px" :maxlength="50"></el-input>
        <div class="message">可填写多个，用逗号分隔，如“申通，圆通”，最大50个字</div>
      </el-form-item>
      <el-form-item label="ERP类型" prop="ERP_type">
        <el-select v-model="ruleForm.ERP_type" placeholder="请选择" style="width: 380px">
          <el-option v-for="(item, index) in ERPList" :label="item.label" :value="item.value" :key="`ERPList_${item.value}`"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="代运营公司" prop="agent_operating_company">
        <el-input v-model.trim="ruleForm.agent_operating_company" placeholder="请输入代运营公司" clearable style="width: 380px"></el-input>
      </el-form-item>

    </el-form>

    <div class="btn-box">
      <el-button @click="$emit('update:currentIndex', 4)">上一步</el-button>
      <el-button class="btn-red" @click="submitForm('ruleForm')">下一步，完善店铺命名</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentIndex: {
      type: Number,
      default: 0,
    },
    ruleForm: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    let validateCompanyWeb = (rule, value, callback) => {
      if (value) {
        const reg = /^https?:\/\/[a-z0-9-]+(\.[a-z0-9-]+)*\.[a-z]+(:\d+)?(\/.*)?$/i
        if (reg.test(value)) {
          return callback();
        }
        return callback(new Error("请输入合法的网站"));
      }
      return callback();
    };
    return {
      rules: {
        company_type: [
          { required: true, message: '请选择开户银行支行所在地', trigger: 'change' },
        ],
        company_web: [
          { validator: validateCompanyWeb, trigger: ['blur', 'change'] }
        ],
        annual_sales: [
          { required: true, message: '请输入年销售额', trigger: 'blur' },
        ],
        goods_num: [
          { required: true, message: '请选择商品数量', trigger: 'change' },
        ],
        avg_price: [
          { required: true, message: '请选择平均客单价', trigger: 'change' },
        ],
        warehouse_situation: [
          { required: true, message: '请选择仓库情况', trigger: 'change' },
        ],
        common_logistics: [
          { required: true, message: '请输入常用物流', trigger: 'blur' },
        ],
        ERP_type: [
          { required: true, message: '请选择ERP类型', trigger: 'change' },
        ],
      },
      companyList: [
        {
          value: 0,
          label: '生产厂商',
        },
        {
          value: 1,
          label: '品牌商',
        },
        {
          value: 2,
          label: '代理商',
        },
        {
          value: 3,
          label: '经销商',
        },
      ],
      goodNumList: [
        {
          value: 0,
          label: '0-100',
        },
        {
          value: 1,
          label: '100-200',
        },
        {
          value: 2,
          label: '200-500',
        },
        {
          value: 3,
          label: '大于500',
        },
      ],
      warehouseList: [
        {
          value: 0,
          label: '自有仓库',
        },
        {
          value: 1,
          label: '第三方仓库',
        },
        {
          value: 2,
          label: '无仓库',
        },
      ],
      ERPList: [
        {
          value: 0,
          label: '自有ERP',
        },
        {
          value: 1,
          label: '第三方ERP代运营',
        },
        {
          value: 2,
          label: '无',
        },
      ],
    }
  },
  created() {
    if (this.$route.query.isAnswer === '1') {
      this.getInfo()
    }
  },
  methods: {
    getInfo() {
      this.$http.axiosGet(this.$api.stuStoreSettingsStepFourInfo, (res) => {
        if (res.code === 200) {
          if (Object.prototype.toString.call(res.data) === '[object Object]') {
            this.$emit('update:ruleForm', {...this.ruleForm, ...res.data})
          }
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            company_type: this.ruleForm.company_type,
            company_web: this.ruleForm.company_web,
            annual_sales: this.ruleForm.annual_sales,
            goods_num: this.ruleForm.goods_num,
            avg_price: this.ruleForm.avg_price,
            warehouse_situation: this.ruleForm.warehouse_situation,
            warehouse_address: this.ruleForm.warehouse_address,
            common_logistics: this.ruleForm.common_logistics,
            ERP_type: this.ruleForm.ERP_type,
            agent_operating_company: this.ruleForm.agent_operating_company,
          }
          this.$http.axiosPost(this.$api.stuStoreSettingsAddStepFour, params, (res) => {
            if (res.code === 200) {
              this.$message.success(res.msg)
              this.$emit('update:currentIndex', 6)
            } else {
              this.$message.warning(res.msg)
            }
          }, (err) => {
            console.log(err)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
.big-title {
  font-size: 16px;
  margin: 35px 0 26px;
}
.message {
  font-size: 12px;
  color: #999;
  line-height: 1.5;
  margin-top: 4px;
}
.btn-box {
  text-align: right;
  padding: 10px 74px;
  border-top: 1px solid #DCDCDC;
  margin: 26px -74px 0 -74px;
}

.blue {
  color: #2461EF;
}
</style>