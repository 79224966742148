<template>
  <div>
    <div class="prompt-info">
      <div>1.信息提交前，请务必先了解<span class="blue">开发平台招商资质标准细则</span>；</div>
      <div>2.公司信息需要项较多，建议先查看<span class="blue">公司信息注意事项</span>再进行填写；</div>
      <div>3.公司信息请严格按照相关证件信息进行确认填写；</div>
      <div>4.以下所需要上传电子版资质仅支持jpg、gif、png格式的图片，大小不超过1M，且必须加盖企业彩色公章。</div>
    </div>
    <div class="big-title">主体信息</div>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px" class="demo-ruleForm" label-suffix=":">
      <el-form-item label="主体类型">
        <el-radio-group v-model="radioType" style="width: 380px">
          <el-radio :label="1">企业</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="证件类型" prop="document_type">
        <el-select v-model="ruleForm.document_type" placeholder="请选择证件类型" style="width: 380px">
          <el-option label="多证合一营业执照（统一社会信用代码）" :value="0"></el-option>
          <el-option label="多证合一营业执照（非统一社会信用代码）" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="营业执照电子版" prop="business_license_src">
        <div class="title-form">
          <div style="line-height: 40px">请上传清晰营业执照图片，营业执照复印件需加盖公司红章扫描上传，若营业执照上</div>
          未体现注册资本、经营范围，请在营业执照后另行上传<span class="blue">企业信息公示网</span>上的截图
        </div>
        <el-upload class="logo-uploader"
                   :action="uploadCover"
                   :headers="headersParams"
                   name="img"
                   :on-change="(file, fileList) => handleChange(file, fileList, 1)"
                   accept=".jpg, .gif, .png, .jpeg"
                   :show-file-list="false"
                   :on-success="(res, file, fileList) => handleCoverSuccess(res, file, fileList, 1)"
                   :before-upload="(file) => beforeCoverUpload(file, 1)">
          <img v-if="ruleForm.business_license_src" :src="ruleForm.business_license_src" class="logo-cover">
          <i v-else class="el-icon-plus logo-uploader-icon"></i>
        </el-upload>
        <div class="message">图片尺寸请确保800px*800px以上，文件大小在5MB以内，支持jpg、gif、png格式，最多可上传1张</div>
      </el-form-item>
      <el-form-item label="公司名称" prop="corporate_name">
        <el-input v-model.trim="ruleForm.corporate_name" placeholder="如有（），请在输入法为中文状态下输入" clearable style="width: 380px"></el-input>
        <div class="message">请按照营业执照上登记的完整名称填写，如有（），请在输入法为中文状态下输入</div>
      </el-form-item>
      <el-form-item label="统一社会信用代码" prop="unified_social_credit_code">
        <el-input v-model.trim="ruleForm.unified_social_credit_code" placeholder="15位的数字" clearable style="width: 380px" :maxlength="15"></el-input>
        <div class="message">请按照营业执照上的注册号进行填写</div>
      </el-form-item>
      <el-form-item label="营业执照所在地" prop="business_license_location">
        <el-cascader :props="{ label: 'value', value: 'code' }" filterable
                     placeholder="请选择营业执照所在地" style="width: 380px" v-model="ruleForm.business_license_location" :options="addressOptions"></el-cascader>
        <div class="message">请按照营业执照上的注册详细地址填写</div>
      </el-form-item>
      <el-form-item label="营业执照详细地址" prop="business_license_address">
        <el-input v-model.trim="ruleForm.business_license_address" placeholder="请输入详细地址" clearable style="width: 380px"></el-input>
      </el-form-item>
      <el-form-item label="成立日期" prop="incorporation_date">
        <el-date-picker v-model="ruleForm.incorporation_date" type="date" placeholder="请选择成立日期" style="width: 380px" value-format="yyyy-MM-dd"></el-date-picker>
      </el-form-item>
      <el-form-item prop="business_date_begin">
        <template #label>
          {{!ruleForm.business_date_type ? '营业日期:' : '营业期限:'}}
        </template>
        <div class="flex" style="width: 380px">
          <el-date-picker v-model="ruleForm.business_date_begin" type="date" :placeholder="!ruleForm.business_date_type ? '开始日期' : '日期期限'"
                          :style="{width: !ruleForm.business_date_type ? '150px' : '312px'}" value-format="yyyy-MM-dd"></el-date-picker>
          <template v-if="!ruleForm.business_date_type">
            <span style="width: 12px; text-align: center">-</span>
            <el-date-picker v-model="ruleForm.business_date_end" type="date" placeholder="结束日期" style="width: 150px" value-format="yyyy-MM-dd"></el-date-picker>
          </template>
          <el-checkbox v-model="ruleForm.business_date_type" style="margin-left: 15px">长期</el-checkbox>
        </div>
      </el-form-item>
      <el-form-item label="注册资本（万元）" prop="registered_capital">
        <el-input-number v-model="ruleForm.registered_capital" class="number-left" :precision="1" :min="0.1"  :max="99999999999.9" label="请输入注册资本" clearable style="width: 380px" :controls="false"></el-input-number>
        <div class="message">若注册资本非人民币，请按照当前汇率换算人民币填写</div>
      </el-form-item>
      <el-form-item label="经营范围" prop="business_scope">
        <el-input v-model.trim="ruleForm.business_scope" placeholder="请输入经营范围" clearable style="width: 380px" type="textarea"></el-input>
        <div class="message">请与营业执照或企业信息公示网的经营范围保持一致</div>
      </el-form-item>
      <el-form-item label="法定代表人证件类型" prop="legal_representative_certificate_type">
        <el-select v-model="ruleForm.legal_representative_certificate_type" placeholder="请选择证件类型" style="width: 380px" @change="toggleLegal">
          <el-option label="大陆身份证" :value="0"></el-option>
          <el-option label="护照" :value="1"></el-option>
          <el-option label="港澳居民通行证" :value="2"></el-option>
          <el-option label="台湾居民通行证" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="法人证件电子版" prop="id_card_front_src">
        <div class="title-form" style="line-height: 40px">
          请按顺序分别上传正面（带有照片一面）和反面电子版图片
        </div>
        <el-upload class="logo-uploader logo-uploader-mul"
                   :action="uploadCover"
                   :headers="headersParams"
                   name="img"
                   :limit="2"
                   :on-exceed="handleExceed"
                   :on-remove="handleRemove"
                   list-type="picture-card"
                   :on-change="(file, fileList) => handleChange(file, fileList, 2)"
                   accept=".jpg, .gif, .png, .jpeg"
                   :file-list="fileListShenfen1"
                   :on-success="(res, file, fileList) => handleCoverSuccess(res, file, fileList, 2)"
                   :before-upload="(file) => beforeCoverUpload(file, 2)">
          <i class="el-icon-plus logo-uploader-icon"></i>
        </el-upload>
        <div class="message">图片尺寸请确保800px*800px以上，文件大小在1MB以内，支持jpg、gif、png格式，最多可上传2张</div>
      </el-form-item>
      <el-form-item label="法定代表人姓名" prop="legal_representative_name">
        <el-input v-model.trim="ruleForm.legal_representative_name" placeholder="请输入法定代表人姓名" clearable style="width: 380px"></el-input>
        <div class="message">请按照营业执照上登记的法人填写</div>
      </el-form-item>
      <el-form-item label="法定代表人证件号" prop="legal_representative_certificates_num">
        <el-input v-model.trim="ruleForm.legal_representative_certificates_num" placeholder="请输入法定代表人证件号" clearable style="width: 380px"></el-input>
      </el-form-item>
      <el-form-item prop="alidity_period_begin">
        <template #label>
          {{!ruleForm.alidity_period_type ? '有效期:' : '营业期限:'}}
        </template>
        <div class="flex" style="width: 380px">
          <el-date-picker v-model="ruleForm.alidity_period_begin" type="date" :placeholder="!ruleForm.alidity_period_type ? '开始日期' : '日期期限'"
                          :style="{width: !ruleForm.alidity_period_type ? '150px' : '312px'}" value-format="yyyy-MM-dd"></el-date-picker>
          <template v-if="!ruleForm.alidity_period_type">
            <span style="width: 12px; text-align: center">-</span>
            <el-date-picker v-model="ruleForm.alidity_period_end" type="date" placeholder="结束日期" style="width: 150px" value-format="yyyy-MM-dd"></el-date-picker>
          </template>
          <el-checkbox v-model="ruleForm.alidity_period_type" style="margin-left: 15px">长期</el-checkbox>
        </div>
      </el-form-item>
    </el-form>

    <div class="btn-box">
      <el-button @click="goBack('ruleForm', 2)">上一步</el-button>
      <el-button class="btn-red" @click="submitForm('ruleForm', 1)">下一步，完善主体信息</el-button>
    </div>
  </div>
</template>

<script>

import {addressList} from "../../../../utils/address";
import {validateCreditCode} from "../../../admin/storeRegister/component/validator";

export default {
  props: {
    currentIndex: {
      type: Number,
      default: 0,
    },
    ruleForm: {
      type: Object,
      default: () => {}
    },
    fileListShenfen: {
      type: Array,
      default: () => []
    },
  },
  data() {
    let validateShenfen = (rule, value, callback) => {
      if (this.fileListShenfen1.length === 0) {
        callback(new Error('请上传身份证'));
      } else if (this.fileListShenfen1.length === 1) {
        callback(new Error('请上传身份证反面'));
      } else if (this.fileListShenfen1.length === 2) {
        callback()
      } else {
        callback(new Error('最多上传两张'));
      }
    };
    let validateDateOperate = (rule, value, callback) => {
      if (!this.ruleForm.business_date_type) {
        if (!this.ruleForm.business_date_begin || !this.ruleForm.business_date_end) {
          return callback(new Error('请选择时间'));
        }
        return callback();
      } else {
        if (!this.ruleForm.business_date_begin) {
          callback(new Error('请选择营业期限'));
        }
        return callback();
      }
    };
    let validateIDNumber = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入证件号'));
      } else {
        const reg = /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/
        const reg1 = /(^[EeKkGgDdSsPpHh]\d{8}$)|(^(([Ee][a-fA-F])|([DdSsPp][Ee])|([Kk][Jj])|([Mm][Aa])|(1[45]))\d{7}$)/
        const reg2 = /^[HMhm]{1}([0-9]{10}|[0-9]{8})$/
        const reg3 = /^\d{8}|^[a-zA-Z0-9]{10}|^\d{18}$/
        if (this.ruleForm.legal_representative_certificate_type === 0) {
          if (reg.test(value)) {
            return callback();
          }
          return callback('请输入合法的身份证');
        }
        if (this.ruleForm.legal_representative_certificate_type === 1) {
          if (reg1.test(value)) {
            return callback();
          }
          return callback('请输入合法的护照');
        }
        if (this.ruleForm.legal_representative_certificate_type === 2) {
          if (reg2.test(value)) {
            return callback();
          }
          return callback('请输入合法的港澳居民通行证');
        }
        if (this.ruleForm.legal_representative_certificate_type === 3) {
          if (reg3.test(value)) {
            return callback();
          }
          return callback('请输入合法的台湾居民通行证');
        }
        callback(new Error("请输入合法的证件号"));
      }
    };
    let validateValidityPeriod = (rule, value, callback) => {
      if (!this.ruleForm.alidity_period_type) {
        if (!this.ruleForm.alidity_period_begin || !this.ruleForm.alidity_period_end) {
          return callback(new Error('请选择时间'));
        }
        return callback();
      } else {
        if (!this.ruleForm.alidity_period_begin) {
          return callback(new Error('请选择营业期限'));
        }
        return callback();
      }
    };

    return {
      fileListShenfen1: this.fileListShenfen,
      radioType: 1,
      // S 上传图片需要
      uploadCover: this.$api.stuStoreSettingsUploadImg,
      headersParams: {
        Authorization: localStorage.getItem('token')
      },
      rules: {
        document_type: [
          { required: true, message: '请选择证件类型', trigger: 'change' },
        ],
        business_license_src: [
          { required: true, message: '请上传营业执照电子版', trigger: 'change' },
        ],
        corporate_name: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
        ],
        unified_social_credit_code: [
          { required: true, validator: validateCreditCode, trigger: ['blur', 'change'] }
        ],
        business_license_location: [
          { required: true, message: '请选择营业执照所在地', trigger: 'change' },
        ],
        business_license_address: [
          { required: true, message: '请输入营业执照详细地址', trigger: 'blur' },
        ],
        incorporation_date: [
          { required: true, message: '请选择成立日期', trigger: 'change' },
        ],
        business_date_begin: [
          { required: true, validator: validateDateOperate, trigger: 'change' },
        ],
        registered_capital: [
          { required: true, message: '请输入注册资本', trigger: 'blur' },
        ],
        business_scope: [
          { required: true, message: '请输入经营范围', trigger: 'blur' },
        ],
        legal_representative_certificate_type: [
          { required: true, message: '请选择法定代表人证件类型', trigger: 'change' },
        ],
        id_card_front_src: [
          { required: true, validator: validateShenfen, trigger: 'change' },
        ],
        legal_representative_name: [
          { required: true, message: '请输入法定代表人姓名', trigger: 'blur' },
        ],
        legal_representative_certificates_num: [
          { required: true, validator: validateIDNumber, trigger: ['blur', 'change'] }
        ],
        alidity_period_begin: [
          { required: true, validator: validateValidityPeriod, trigger: 'change' }
        ],
      },
      // 营业执照所在地
      addressOptions: addressList,
    }
  },
  created() {
    if (this.$route.query.isAnswer === '1') {
      this.getInfo()
    }
  },
  methods: {
    goBack(formName, type) {
      if (this.$route.query.isAnswer === '1') {
        this.submitForm(formName, type)
      }
      this.$emit('update:currentIndex', 2)
    },
    toggleLegal() {
      if (this.ruleForm.legal_representative_certificates_num) {
        this.$refs.ruleForm.validateField('legal_representative_certificates_num')
      }
    },
    getInfo() {
      this.fileListShenfen1 = []
      this.$emit('update:fileListShenfen', [])
      this.$http.axiosGet(this.$api.stuStoreSettingsStepTwoInfo, (res) => {
        if (res.code === 200) {
          if (Object.prototype.toString.call(res.data) === '[object Object]') {
            let business_license_location = [res.data.business_license_location_province, res.data.business_license_location_city, res.data.business_license_location_area]
            let business_date_type = !!res.data.business_date_type;
            let alidity_period_type = !!res.data.alidity_period_type;
            this.$emit('update:ruleForm', {...this.ruleForm, ...res.data, business_license_location, business_date_type, alidity_period_type})
            if (res.data.id_card_front_src && res.data.id_card_reserve_src) {
              this.fileListShenfen1.push(
                {
                  name: '身份证正面',
                  url: res.data.id_card_front_src,
                  response: {
                    data: res.data.id_card_front_src
                  }
                }
              )
              this.fileListShenfen.push(
                {
                  name: '身份证正面',
                  url: res.data.id_card_front_src,
                  response: {
                    data: res.data.id_card_front_src
                  }
                }
              )
              this.fileListShenfen1.push(
                  {
                    name: '身份证反面',
                    url: res.data.id_card_reserve_src,
                    response: {
                      data: res.data.id_card_reserve_src
                    }
                  },
              )
              this.fileListShenfen.push(
                  {
                    name: '身份证反面',
                    url: res.data.id_card_reserve_src,
                    response: {
                      data: res.data.id_card_reserve_src
                    }
                  },
              )
            }
          }
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    submitForm(formName, type) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            document_type: this.ruleForm.document_type,
            business_license_src: this.ruleForm.business_license_src,
            corporate_name: this.ruleForm.corporate_name,
            unified_social_credit_code: this.ruleForm.unified_social_credit_code,
            business_license_location_province: this.ruleForm.business_license_location[0],
            business_license_location_city: this.ruleForm.business_license_location[1],
            business_license_location_area: this.ruleForm.business_license_location[2],
            business_license_address: this.ruleForm.business_license_address,
            incorporation_date: this.ruleForm.incorporation_date,
            business_date_begin: this.ruleForm.business_date_begin,
            // business_date_end: this.ruleForm.business_date_end
            business_date_type: this.ruleForm.business_date_type,
            registered_capital: this.ruleForm.registered_capital,
            business_scope: this.ruleForm.business_scope,
            legal_representative_certificate_type: this.ruleForm.legal_representative_certificate_type,
            id_card_front_src: this.fileListShenfen1[0].response.data,
            id_card_reserve_src: this.fileListShenfen1[1].response.data,
            legal_representative_name: this.ruleForm.legal_representative_name,
            legal_representative_certificates_num: this.ruleForm.legal_representative_certificates_num,
            alidity_period_begin: this.ruleForm.alidity_period_begin,
            // alidity_period_end: this.ruleForm.alidity_period_end
            alidity_period_type: this.ruleForm.alidity_period_type,
          }
          if (this.ruleForm.business_date_type) {
            params.business_date_type = 1
            params.business_date_end = ''
          } else {
            params.business_date_type = 0
            params.business_date_end = this.ruleForm.business_date_end
          }
          if (this.ruleForm.alidity_period_type) {
            params.alidity_period_type = 1
            params.alidity_period_end = ''
          } else {
            params.alidity_period_type = 0
            params.alidity_period_end = this.ruleForm.alidity_period_end
          }
          if (this.ruleForm.id) {
            params.id = this.ruleForm.id
          }
          this.$http.axiosPost(this.$api.stuStoreSettingsAddStepTwo, params, (res) => {
            if (res.code === 200) {
              if (type === 1) {
                this.$message.success(res.msg)
              }
              this.$emit('update:currentIndex', 4)
            } else {
              this.$message.warning(res.msg)
            }
          }, (err) => {
            console.log(err)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // start 上传图片
    beforeCoverUpload(file, type) {
      const isLt5M = file.size / 1024 / 1024 <= 5;
      const isLt1M = file.size / 1024 / 1024 <= 1;
      if (type === 1 && !isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      if (type === 2 && !isLt1M) {
        this.$message.error('上传图片大小不能超过 1MB!');
      }
      const isSize = new Promise((resolve, reject) => {
        const width = 800
        const height = 800
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = () => {
          const valid = img.width >= width && img.height >= height
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return file
      }, () => {
        this.$message.warning('图片尺寸请确保800px*800px以上')
        return Promise.reject()
      })
      if (type === 1) {
        return isLt5M && isSize
      } else {
        return isLt1M && isSize
      }
    },
    handleCoverSuccess(res, file, fileList, type) {
      if (res.code === 200) {
        if (type === 1) {
          this.ruleForm.business_license_src = res.data; //无域名
        } else {
          let obj = {
            ...file,
            url: res.data
          }
          // this.$emit('update:fileListShenfen', fileList)
          this.fileListShenfen.push(obj)
          this.fileListShenfen1 = fileList
        }
        this.$message.success('上传成功')
      } else {
        this.$message.error('图片上传失败，请稍后再试~')
      }
    },
    handleChange(uploadFile, uploadFiles, type) {
      if (type === 1 && this.ruleForm.business_license_src) {
        this.$refs.ruleForm.validateField('business_license_src')
      }
      if (type === 2 && this.fileListShenfen1.length > 0) {
        this.$refs.ruleForm.validateField('id_card_front_src')
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 2 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    handleRemove(file, fileList) {
      let findIndex = this.fileListShenfen.findIndex(item => item.uid === file.uid)
      this.fileListShenfen.splice(findIndex, 1)

      this.fileListShenfen1 = fileList
      this.$refs.ruleForm.validateField('id_card_front_src')
    },
    // end 上传图片
  }
}
</script>

<style scoped lang="scss">
.big-title {
  font-size: 16px;
  margin: 35px 0 26px;
}
.message {
  font-size: 12px;
  color: #999;
  line-height: 1.5;
  margin-top: 4px;
}
.title-form {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}
.btn-box {
  text-align: right;
  padding: 10px 74px;
  border-top: 1px solid #DCDCDC;
  margin: 26px -74px 0 -74px;
}

:deep(.logo-uploader) {
  width: 120px;
  height: 120px;
  .el-upload {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F7F7F7;
  }
  .logo-cover {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
  .logo-uploader-icon {
    color: #999;
    font-size: 24px;
  }
  &.logo-uploader-mul {
    display: flex;
    width: 100%;
    .el-upload {
      &.el-upload--picture-card {
        width: 120px;
        height: 120px;
        border: none;
        background: #F7F7F7;
      }
    }
    .el-upload-list__item {
      width: 120px;
      height: 120px;
    }
  }
}
.blue {
  color: #2461EF;
}
.prompt-info {
  background: #FFFDF0;
  border: 1px solid #FFC8A0;
  padding: 12px 46px;
  line-height: 30px;
}
</style>