import { render, staticRenderFns } from "./Set.vue?vue&type=template&id=08ec1379&scoped=true"
import script from "./Set.vue?vue&type=script&lang=js"
export * from "./Set.vue?vue&type=script&lang=js"
import style0 from "./Set.vue?vue&type=style&index=0&id=08ec1379&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08ec1379",
  null
  
)

export default component.exports